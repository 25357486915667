import React from 'react'
import "./styledHome.css"

function Home({initMode, setInitMode, homeMode, setHomeMode}) {

    function handleStart () {
        setInitMode(true)
        setHomeMode(false)
    }


    return (

        <button 
        onClick = {() =>handleStart()}
        
        className='glowing-btn'>
            <span className='glowing-txt'>STA<span className='faulty-letter'>R</span>T</span>
        
        </button>
    )
}

export default Home

