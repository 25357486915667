import React from 'react';




function HUD({player1, player2, gameOver, turn, toggleGameBoard, playAnotherRound}) {
    

    
    return (
        <h1>
            <span>{player1}</span>
            &nbsp; Vs &nbsp; 
            <span>{player2} </span>
            { gameOver ?
                <>
                    { (turn === player1) ?
                        <h2 className = "winner-text"> {player2} Wins! </h2>
                        :
                        <h2 className = "winner-text"> {player1} Wins! </h2>
                    }
                    <button onClick = {playAnotherRound}>Play Another Round</button>
                </>
                :
                <></>
            }
        </h1>
    )
}

export default HUD


