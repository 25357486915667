import React, { useEffect, useState } from "react";
import { EditText } from "react-edit-text";
import "./styledNewGame.css";

function NewGame({
    player1,
    player2,
    setPlayer1,
    setPlayer2,
    handlePlayerName,
    initMode,
    setInitMode,
    gameplayMode,
    setGameplayMode
}) {
    const [p1Ready, setP1Ready] = useState(false);
    const [p2Ready, setP2Ready] = useState(false);
    const [p1BtnClasses, setP1BtnClasses] = useState("ready-button");
    const [p1BtnText, setP1BtnText] = useState("Ready?");
    const [p2BtnClasses, setP2BtnClasses] = useState("ready-button");
    const [p2BtnText, setP2BtnText] = useState("Ready?");

    useEffect(() => {
        if (p1Ready) {
            setP1BtnClasses("ready-button p1-is-ready");
            setP1BtnText(`${player1} Ready!`)
        } else {
            setP1BtnClasses("ready-button");
        }
        if (p2Ready) {
            setP2BtnClasses("ready-button p2-is-ready");
            setP2BtnText(`${player2} Ready!`)
        } else {
            setP2BtnClasses("ready-button");
        }

        if (p1Ready && p2Ready) {
            setInitMode(!initMode);
            setGameplayMode(!gameplayMode);
        }
    }, [p1Ready, p2Ready, player1, player2, gameplayMode, setInitMode, setGameplayMode, initMode]);

    // useEffect(() => {
    //     if()
    // }, [player1, player2])

    function handleP1Ready() {
        if (player1) {
            setP1Ready(!p1Ready);
        }
    }

    function handleP2Ready() {
        if(player2) {
            setP2Ready(!p2Ready);
        }
    }

    return (
        <>
            {initMode ? (
                <>
                    
                    <div>
                        Player 1
                        <div>
                            <EditText
                                className="player-text-box"
                                onChange={(e) => handlePlayerName(e)}
                                name="player1"
                                inputClassName="player1"
                                placeholder="Enter player 1 name"
                                value={player1}
                            />
                            <button
                                onClick={handleP1Ready}
                                className={p1BtnClasses}
                            >
                                {p1BtnText}
                            </button>
                        </div>
                    </div>
                    
                    
                    <div>
                        Player 1
                        <div>
                            <EditText
                                className="player-text-box"
                                onChange={(e) => handlePlayerName(e)}
                                name="player2"
                                inputClassName="player2"
                                placeholder="Enter player 2 name"
                                value={player2}
                            />
                            <button
                                onClick={handleP2Ready}
                                className={p2BtnClasses}
                            >
                                {p2BtnText}
                            </button>
                        </div>
                    </div>

                </>
            ) : (
                <></>
            )}
        </>
    );
}

export default NewGame;
