import React from 'react';
import './styledBoard.css'
// import {handleTurn} from './Helper'
// import Game from './Game'


function Board({handleTurn, handleWin, a1Text, b1Text, c1Text, a2Text, b2Text, c2Text, a3Text, b3Text, c3Text}) {





    return (
        
        <div>
            
            <div className = "game-board">
            
                <button onClick = {handleTurn} name = 'a1' className = "tile" value={a1Text}> {a1Text} </button>
                <button onClick = {handleTurn} name = 'b1' className = "tile" value={b1Text}> {b1Text} </button>
                <button onClick = {handleTurn} name = 'c1' className = "tile" value={c1Text}> {c1Text} </button>

                <button onClick = {handleTurn} name = 'a2' className = "tile" value={a2Text}> {a2Text} </button>
                <button onClick = {handleTurn} name = 'b2' className = "tile" value={b2Text}> {b2Text} </button>
                <button onClick = {handleTurn} name = 'c2' className = "tile" value={c2Text}> {c2Text} </button>

                <button onClick = {handleTurn} name = 'a3' className = "tile" value={a3Text}> {a3Text} </button>
                <button onClick = {handleTurn} name = 'b3' className = "tile" value={b3Text}> {b3Text} </button>
                <button onClick = {handleTurn} name = 'c3' className = "tile" value={c3Text}> {c3Text} </button>

            </div>
        </div>
    )
}

export default Board
