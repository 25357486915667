// import logo from './logo.svg';
import './App.css';
// import Board from './components/Board'
import Game from './components/Game'


function App() {
  return (
    <div className="App">
      <Game />
    </div>
  );
}

export default App;
