import React, { useState, useEffect } from "react";
// import { EditText, EditTextarea } from "react-edit-text";
import Board from "./Board";
import "./styledBoard.css"
import HUD from "./HUD.jsx"
import NewGame from "./NewGame.jsx"
import Home from "./Home.jsx"


//determine player

function Game() {


    const [player1, setPlayer1] = useState("K1");
    const [player2, setPlayer2] = useState("k2");
    // const [gamesPlayed, setGamesPlayed] = useState(0);
    const [xo, setXO] = useState("X");
    const [gameOver, setGameOver] = useState(false);
    const [homeMode, setHomeMode] = useState(true)
    const [initMode, setInitMode] = useState(false)
    const [gameplayMode, setGameplayMode] = useState(false)
    // const [gameOverMode, setGameOverMode] = useState(false)
    const [turn, setTurn] = useState(player1);
    
    function handlePlayerName(e) {
        const player = e.target.name;
        const value = e.target.value;
        if (value && player === "player1") setPlayer1(value);
        if (value && player === "player2") setPlayer2(value);
    }
    // "id" : gamesPlayed,
    // "player1" : player1,
    // "player2" : player2,
    // "winner" : "",
    // const initialBoardState = [
    //     {
    //         a1: "",
    //         b1: "",
    //         c1: "",
    //         a2: "",
    //         b2: "",
    //         c2: "",
    //         a3: "",
    //         b3: "",
    //         c4: "",
    //     },
    // ];
    // const [board, setBoard] = useState(initialBoardState);


    const [a1Text, seta1Text] = useState("")
    const [b1Text, setb1Text] = useState("")
    const [c1Text, setc1Text] = useState("")
    const [a2Text, seta2Text] = useState("")
    const [b2Text, setb2Text] = useState("")
    const [c2Text, setc2Text] = useState("")
    const [a3Text, seta3Text] = useState("")
    const [b3Text, setb3Text] = useState("")
    const [c3Text, setc3Text] = useState("")




    useEffect(() => {

        function handleWin() {
            if (
                (a1Text === "X" && b1Text === "X" && c1Text === "X") ||
                (a2Text === "X" && b2Text === "X" && c2Text === "X") ||
                (a3Text === "X" && b3Text === "X" && c3Text === "X") ||
                (a1Text === "X" && a2Text === "X" && a3Text === "X") ||
                (b1Text === "X" && b2Text === "X" && b3Text === "X") ||
                (c1Text === "X" && c2Text === "X" && c3Text === "X") ||
                (a1Text === "X" && b2Text === "X" && c3Text === "X") ||
                (a3Text === "X" && b2Text === "X" && c1Text === "X") ||
                (a1Text === "O" && b1Text === "O" && c1Text === "O") ||
                (a2Text === "O" && b2Text === "O" && c2Text === "O") ||
                (a3Text === "O" && b3Text === "O" && c3Text === "O") ||
                (a1Text === "O" && a2Text === "O" && a3Text === "O") ||
                (b1Text === "O" && b2Text === "O" && b3Text === "O") ||
                (c1Text === "O" && c2Text === "O" && c3Text === "O") ||
                (a1Text === "O" && b2Text === "O" && c3Text === "O") ||
                (a3Text === "O" && b2Text === "O" && c1Text === "O")
            ) {
                setGameOver(true)
    
            }
        }
        handleWin()
    },[a1Text, b1Text, c1Text, a2Text, b2Text, c2Text, a3Text, b3Text, c3Text, player1, player2])

    useEffect(() => {
        if (turn === player1) {
            setXO("X")
        }
        if (turn === player2) {
            setXO("O")
        }    
    }, [player1, player2, turn])

    function handleTurn(e) {

        console.log(gameOver);
        console.log("player1: " + player1, "player2: " + player2, "turn: " + turn)
        console.log("XO: "+xo)

        if (gameOver || e.target.value !== "") {
            return;
        }

        let tile = e.target.name;
        // let value = e.target.value;

        if (tile === "a1") seta1Text(xo);
        if (tile === "b1") setb1Text(xo);
        if (tile === "c1") setc1Text(xo);
        if (tile === "a2") seta2Text(xo);
        if (tile === "b2") setb2Text(xo);
        if (tile === "c2") setc2Text(xo);
        if (tile === "a3") seta3Text(xo);
        if (tile === "b3") setb3Text(xo);
        if (tile === "c3") setc3Text(xo);

        if (turn === player1) {
            e.target.value = "X";
            setTurn(player2);
            setXO("O");
        }

        if (turn === player2) {
            e.target.value = "O";
            setTurn(player1);
            setXO("X");
        }


    }



    function toggleGameBoard() {
        setGameplayMode(!gameplayMode)
    }

    function playAnotherRound() {
        setTurn(player1)
        setGameOver(false)
        setXO("X")
        seta1Text("")
        setb1Text("")
        setc1Text("")
        seta2Text("")
        setb2Text("")
        setc2Text("")
        seta3Text("")
        setb3Text("")
        setc3Text("")
    }

    // console.log("player1: " + player1, "player2: " + player2, "turn: " + turn)

    return (
        <>

            {homeMode ?
                <Home
                    homeMode = {homeMode}
                    setHomeMode = {setHomeMode}
                    initMode={initMode}
                    setInitMode={setInitMode}
                />
                :
                <></>
            }


            {initMode ?
                <NewGame 
                player1 = {player1}
                setPlayer1={setPlayer1}
                player2 = {player2}
                setPlayer2={setPlayer2}
                handlePlayerName = {handlePlayerName}
                initMode = {initMode}
                setInitMode = {setInitMode}
                gameplayMode = {gameplayMode}
                setGameplayMode = {setGameplayMode}
                />
                :
                <></>
            }







            {gameplayMode ?
            <>
                <HUD 
                    player1 = {player1} 
                    player2 = {player2}
                    gameOver = {gameOver}
                    turn = {turn}
                    gameplayMode = {gameplayMode}
                    setGameplayMode = {setGameplayMode}
                    toggleGameBoard = {toggleGameBoard}
                    playAnotherRound = {playAnotherRound}
                    />

                <Board
                    handleTurn={handleTurn}
                    player1={player1}
                    setPlayer1={setPlayer1}
                    player2={player2}
                    setPlayer2={setPlayer2}
                    a1Text={a1Text}
                    b1Text={b1Text}
                    c1Text={c1Text}
                    a2Text={a2Text}
                    b2Text={b2Text}
                    c2Text={c2Text}
                    a3Text={a3Text}
                    b3Text={b3Text}
                    c3Text={c3Text}
                />
            </>
                :
                <></>
            }


        </>
    );
}

export default Game;
